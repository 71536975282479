import { Container, SimpleGrid, Text } from "@chakra-ui/react"
import { useState, useEffect, useRef } from 'react';
import { doc, onSnapshot} from "firebase/firestore";
import firebase from '../firebase'
import PropertyCard from "./PropertyCard";
import StockProp from '../stockprop.jpeg';
import { User } from "firebase/auth";
import { useAuth } from "../AuthContext"

function Properties() {

  interface Property {
    name: string; 
    value: string;
    color: string;
    imageurl: string;
  }
  

  const [properties, setProperties] = useState<Property[]>([])
  const [loading, setLoading] = useState(true); 
  const unsubRef = useRef(() => finishUnsub());

  const { currentUser } = useAuth()

  function finishUnsub(){};
  

  useEffect(() => {
    loadPropertiesFromDatabase(currentUser)
    setTimeout(() => setLoading(false), 1000);

    return () => {
      unsubRef.current && unsubRef.current()
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[]); 

  const loadPropertiesFromDatabase = async function(u: User) {

    const unsub = onSnapshot(
      doc(firebase.db, "properties", u.uid),
      (doc) => {
        const pp: Property[] = [];
        const ps = doc.data()
        for (var p in ps) { 
          const pdelt: Property = {
            name: p,
            value: ps[p].propertyvalue,
            color: ps[p].propertycolor,
            imageurl: ps[p].propertyimageurl,
          }
          pp.push(pdelt)
        }
        setProperties(pp)
      });
      
      unsubRef.current = unsub
  
  }
  if (loading) {return null}
  
  return (
    <Container maxW={'10xl'} maxHeight="100%" overflowY="auto">
        <SimpleGrid rounded={'lg'} p={3} columns={{sm: 2, md: 3, lg: 4}} spacingY='20px' justifyItems={'center'}>
          {
              properties.length === 0 ?           
              <Text> No Properties on File</Text>
              : 
              properties.map((p) => 
                <PropertyCard key={p.name}
                  name = {p.name}
                  value = {p.value}
                  color={p.color}
                  imageurl={p.imageurl === "" ? StockProp: p.imageurl }
                />
              )
          }
         </SimpleGrid>
    </Container>
  );
}
export default Properties;