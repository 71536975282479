import {
    Box,
    Stack
  } from "@chakra-ui/react"

function DashboardBar() {

  return (
        <Box>
            <Stack direction='row' spacing={4} align='center' justifyContent={'center'}>
            </Stack>
        </Box>
  );
}
export default DashboardBar;        
