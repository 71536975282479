import {
    Box,
    Text,
    Heading,
    Button,
    Image,
    Stack,
    Container,
    Flex,
    ButtonGroup,
    Spacer,
    Badge,
  } from "@chakra-ui/react"

import {Link as Route} from "react-router-dom"
import House from './modernhouse.jpeg';
import llogo from "./logo.png"

function CustomScroll(id:string){
  document.getElementById(id)!.scrollIntoView({behavior: "smooth"});
};

function Hero() {

  return (
    <Container maxW={'7xl'} minHeight="100vh">
    <Box>
      <Flex minWidth='max-content' alignItems='center' gap='2'>
        <Box position="relative"
              left="-3">
          <Image
                
                borderRadius='full'
                boxSize='100px'
                src={llogo}
                alignSelf={"start"}
            />
        </Box>
        <Spacer />
        <ButtonGroup gap='2'>
          <Button
            //onClick={() => landlordLoginClick()}
            as={Route} 
            to='/login'
            fontWeight={600}
            bg={'green.400'}
            w={{base:'33vw', md: '20vw', lg: '15vw'}}
            fontSize={"inherit"}
          >
            Landlord Login
          </Button>
          <Button
            as={Route} to='/payment'
            fontWeight={600}
            fontSize={"inherit"}
            bg={'pink.400'}
            w={{base:'33vw', md: '24vw', lg: '15vw'}}
            _hover={{
              bg: 'pink.300',
            }}>
            Payment Portal
          </Button>
        </ButtonGroup>
      </Flex>
    </Box>
    <Stack
      align={'center'}
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
      direction={{ base: 'column', md: 'row' }}>
      <Stack flex={1} spacing={{ base: 5, md: 10 }} ml={0}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '4xl', sm: '5xl', lg: '7xl' }}>
          <Text
            as={'span'}
            position={'relative'}
          >
            LANDLORDE
          </Text>
          <Badge colorScheme='red' position={'absolute'}>BETA</Badge>
          <br />
          <Text as={'span'} fontSize='2xl'>
            The modern property and tenant mangement tool.
          </Text>
        </Heading>
        <Text color={'gray.500'}>
          The platform that you need to streamline property management processes
          and procdeures. Upload information and store it for easy and simple 
          access. An intuitive user interface for all.
        </Text>
        <Stack
          spacing={{ base: 4, sm: 6 }}
          direction={{ base: 'column', sm: 'row' }}>
          <Button
              as={Route} to='/signup'
              size={'md'}
              fontWeight={600}
              px={6}
              bg={'red.400'}
              _hover={{ bg: 'red.500' }}>
              Sign Up
          </Button>
          <Button
            //as={Link}
            onClick={() => CustomScroll("features")}
            //href = "#features"
            size={'md'}
            fontWeight={600}
            px={6}
            bg={'blue.400'}
          >
            Features
          </Button>
          <Button
            //as={Link}
            //href = "#pricing"
            onClick={() => CustomScroll("pricing")}
            fontWeight={600}
            px={6}
            bg={'yellow.500'}
            size={'md'}
          >
            Pricing
          </Button>
        </Stack>
      </Stack>
      <Flex
        flex={1}
        justify={'center'}
        align={'center'}
        position={'relative'}
        w={'full'}>
        <Box
          position={'relative'}
          height={'400px'}
          rounded={'2xl'}
          boxShadow={'2xl'}
          width={'full'}
          overflow={'hidden'}>
          <Image
            alt={'House Image'}
            align={'center'}
            w={'100%'}
            h={'100%'}
            src={House}
          />
        </Box>
      </Flex>
    </Stack>
  </Container>
  );
}
export default Hero;