import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore} from "firebase/firestore";
import 'firebase/storage'
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

/*
const firebaseConfig = {
    apiKey: "AIzaSyC-vUXGgPiiaGjgovNhnVlgpMHgQQQtmgY",
    authDomain: "landlorde-backend.firebaseapp.com",
    projectId: "landlorde-backend",
    storageBucket: "landlorde-backend.appspot.com",
    messagingSenderId: "681945090738",
    appId: "1:681945090738:web:51df4fe208a93ea9e557ab",
    measurementId: "G-7K68FRSQ08"
  };
*/


  const firebaseConfig = {
  apiKey: "AIzaSyBWNnFPSP8A2Cpbd85r1x7a6AM8PLkw2bw",
  authDomain: "landlorde-backend-dev.firebaseapp.com",
  projectId: "landlorde-backend-dev",
  storageBucket: "landlorde-backend-dev.appspot.com",
  messagingSenderId: "704135690117",
  appId: "1:704135690117:web:26a57f8428cc1507dc36ec",
  measurementId: "G-MGXM879J3X"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app)
const analytics = getAnalytics(app);

const fb = {
  app, 
  auth, 
  db, 
  storage,
  analytics,
};

export default fb;