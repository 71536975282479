import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Menu,
  ChakraProvider,
  theme,
  Container,
  Text,
  Grid, GridItem, VStack, Heading
} from '@chakra-ui/react';

import { ColorModeSwitcher } from "../ColorModeSwitcher"
import { User } from "firebase/auth";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {getDoc, doc} from "firebase/firestore";
import Properties from '../components/Properties';
import Applications from './Applications';
import Billing from './Billing';
import MyProfile from './MyProfile';
import firebase from '../firebase'
import PropertiesBar from '../components/PropertiesBar'
import Dashboard from '../components/Dashboard';
import DashboardBar from '../components/DashboardBar';
import { useAuth } from "../AuthContext"

function AppMenu(){
  const { logout } = useAuth()
  const { currentUser } = useAuth()

  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [profileimageurl, setProfileImageURL] = useState("")
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
        
        let authToken = sessionStorage.getItem('Auth Token')

        if (authToken) {
            loadProfileFromDatabase(currentUser)
            setTimeout(() => setLoading(false), 2000);
        }

        if (!authToken) {
            navigate('/')
        }
        
  });

  const [c, setC] = useState("p"); 
  const [b, setB] = useState("p")
  const [t, setT] = useState("Properties")

  function showItems(id: string) { 
    if(id === "d"){
      setC("d")
      setB("d")
      setT("Dashboard")
    }
    if(id === "p"){
      setC("p")
      setB("p")
      setT("Properties")
    }
    if(id === "a"){
      setC("a")
      setB("a")
      setT("Applications")
    }
    if(id === "m"){
      setC("m")
      setB("m")
      setT("My Profile")
    }
    if(id === "b"){
      setC("b")
      setB("b")
      setT("Billing")
    }
    
  } 

  let navigate = useNavigate()

  async function signout(){

    try {
      await logout()
      navigate('/')
      sessionStorage.removeItem('Auth Token');
      //navigate('/')
    } catch {
      console.log("Error Logging Out")
    }
  }
  
  async function loadProfileFromDatabase (u: User){
    
    if(u == null){
      signout()
    }

    const docRef = doc(firebase.db, "users", u.uid);
    const docSnap = await getDoc(docRef);
    const profile = docSnap.data()
    setFirstName(profile?.firstname)
    setLastName(profile?.lastname)
    setProfileImageURL(profile?.profileimageurl)
  }
  if (loading) {
    console.log("nully")
    return null
  }
 
  return(

    <ChakraProvider theme={theme}>
    <ColorModeSwitcher justifySelf="flex-start" />
      <Container maxW={'9xl'} minHeight="100vh">
        <Box mb={3}>
              <Flex>
                <Flex justifyContent={"center"} alignItems={'center'}>
                  <Text fontWeight={800} fontSize='xl'> {firstname} {lastname} </Text>
                </Flex>
                <Flex alignItems={'center'} ml={2}>
                  <Menu>
                      <Avatar
                        size={'sm'}
                        src={profileimageurl}
                      />
                  </Menu>
                </Flex>
                <HStack
                  flex={{ base: 1, md: 5, lg: 5 }}
                  justify={'flex-end'}
                  direction={'row'}
                  spacing={6}>
                  <Button
                    onClick={() => signout()}
                    fontWeight={600}
                    bg={'pink.400'}
                    _hover={{
                      bg: 'pink.300',
                    }}>
                    Sign Out
                  </Button>
                </HStack>
              </Flex>
          </Box>
        
      <Grid
          minHeight='85vh'
          templateRows='repeat(12, 1fr)'
          templateColumns='repeat(8, 1fr)'
          gap={4}
          >
          <GridItem  rowStart={0} rowEnd={12} rowSpan={12} colSpan={1}  display={"flex"}
            alignItems="center"  rounded='md' justifyContent={"center"}>
              <VStack w={'80%'}>
                <Button id="1" onClick={() => showItems("d")} w={'100%'}>Dashboard</Button>
                <Button id="2" onClick={() => showItems("p")} w={'100%'}>Properties</Button>
                <Button id="3" onClick={() => showItems("a")} w={'100%'}>Applications</Button>
                <Button id="4" onClick={() => showItems("m")} w={'100%'}>My Profile</Button>
                <Button id="5" onClick={() => showItems("b")} w={'100%'}>Billing</Button>
              </VStack>
          </GridItem>
          <GridItem rowSpan={2} colSpan={7} rounded='md'  display={{ md: "flex" }}  alignItems="center">
            <Heading as='h2' size='lg'>{t}</Heading>
          </GridItem> 
          <GridItem rowSpan={9} border='1px' colSpan={7} rounded='md'h={'600px'}>
                {c === "d" && <Dashboard />}
                {c === "p" && <Properties/>}
                {c === "a" && <Applications />}
                {c === "m" && <MyProfile />}
                {c === "b" && <Billing />}
          </GridItem>
          <GridItem rowSpan={1} colSpan={7} rounded='md'>
                {b === "d" && <DashboardBar />}
                {b === "p" && <PropertiesBar />}
                {b === "a" && <DashboardBar />}
                {b === "m" && <DashboardBar />}
                {b === "b" && <DashboardBar />}
          </GridItem>
      </Grid>
      </Container>
    </ChakraProvider> 

  );
}

export default AppMenu;