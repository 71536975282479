import { ChakraProvider, Box, Text, Heading, Button, theme, Stack, Container, Flex,Checkbox, Link, FormLabel, FormControl, Input, useColorModeValue,Image, FormErrorMessage} from "@chakra-ui/react"
import { ColorModeSwitcher } from "../ColorModeSwitcher"
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { UserCredential } from "firebase/auth"
import { useAuth } from "../AuthContext"
import { FirebaseError } from "firebase/app"
import llogo from "../logo.png"


function LandlordLogin(){

  let navigate = useNavigate()

  const { login } = useAuth()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErrorMessage,setEmailErrorMessage] = useState("")
  const [passErrorMessage,setPassErrorMessage] = useState("")
  const [badEmail, setBadEmail] = useState(false)
  const [badPass, setBadPass] = useState(false)

  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token')
    if (authToken) { navigate('/app') }
  }); 

  async function loginUser(){
    try {
      const uc : UserCredential = await login(email, password)
      const u = uc.user;
      navigate('/app')
      sessionStorage.setItem('Auth Token', u!.refreshToken)
    } 
    catch(error: unknown) {
      if (error instanceof FirebaseError) {
        if (error.code === "auth/user-not-found") {
          setBadEmail(true)
          setBadPass(false)
          setEmailErrorMessage("No user account with provided email found!");
        } else if (error.code === "auth/wrong-password") {
          setBadPass(true)
          setBadEmail(false)
          setPassErrorMessage("Incorrect password!");
        } else if (error.code === "auth/invalid-email") {
          setBadEmail(true)
          setBadPass(false)
          setEmailErrorMessage("Invalid email provided");
        }
      }
      else{
        console.log("Non-Firebase Error")
      }
    }
  }
  
  return (
  <ChakraProvider theme={theme}>

    <ColorModeSwitcher justifySelf="flex-start" />
    <Container maxW={'7xl'}>
        <Box>
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Button height={'100px'} bgColor={"transparent"} _hover={{ bg: "transparent" }}  _active={{ color: 'transparent' }} onClick={() => navigate("/")}>
          <Image
                borderRadius='full'
                boxSize='100px'
                src={llogo}
                alignSelf={"start"}
            />
          </Button>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Sign in to your account</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            boxShadow={'lg'}
            p={8}
            bg={useColorModeValue('white', 'white')}
            color={useColorModeValue('black', 'black')}
            >
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={badEmail}>
                <FormLabel>Email</FormLabel>
                <Input onChange={(e) => { setEmail(e.target.value); }} type="email" borderColor={'lightgray'} required/>
                <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={badPass}>
                <FormLabel>Password</FormLabel>
                <Input onChange={(e) => { setPassword(e.target.value); }} type="password" borderColor={'lightgray'} required/>
                <FormErrorMessage>{passErrorMessage}</FormErrorMessage>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                  <Checkbox borderColor={'lightgray'} >Remember me</Checkbox>
                  <Link color={'blue.400'}>Forgot password?</Link>
                </Stack>
                <Button
                  //type="submit"
                  onClick={() => loginUser()}
                  bg={'green.400'}
                  color={'white'}
                  _hover={{
                    bg: 'green.500',
                  }}>
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
        </Box>
    </Container>
  </ChakraProvider>
)
}
export default LandlordLogin;
