import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"

import Pricing from "../Pricing";
import Features from "../Features";
import Hero from "../Hero";
import Footer from "../Footer"

import { ColorModeSwitcher } from "../ColorModeSwitcher"

export const Home = () => (
  
  <ChakraProvider theme={theme}>
    <ColorModeSwitcher justifySelf="flex-start" />
      <Hero />
      <Features />
      <Pricing />
      <Footer />
  </ChakraProvider>
)

export default Home;