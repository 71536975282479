import {
  Text,
  Heading,
  Image,
  Stack,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  StackDivider,
  Center,
} from "@chakra-ui/react"

import Tech from './tech.jpeg'
import { FaSearch, FaChartArea, FaMoneyCheckAlt } from "react-icons/fa";
import { ReactElement } from 'react';

interface FeatureProps {
  text: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function Features() {
  return (
    <Container id = "features" maxW={'7xl'} minHeight="100vh">
    <Center h='100vh'>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
      <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              Tech
            }
            objectFit={'cover'}
          />
        </Flex>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'blue.400'}
            fontWeight={600}
            fontSize={'sm'}
            alignSelf={'flex-start'}
            rounded={'md'}>
            Features
          </Text>
          <Heading>All in one platform.</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
            Explore all of Landlorde's features! All of the resources
            needed to maintain properties are included.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
              />
            }>
            <Feature
              icon={
                <Icon as={FaChartArea} color={'yellow.500'} w={5} h={5} />
              }
              text={'Track Property Cash Flow'}
            />
            <Feature
              icon={<Icon as={FaMoneyCheckAlt} color={'green.500'} w={5} h={5} />}
              text={'Accept Tenant Payments'}
            />
            <Feature
              icon={
                <Icon as={FaSearch}  color={'purple.500'} w={5} h={5} />
              }
              text={'Analyze Property Features and Data'}
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Center>
  </Container>
  );
}