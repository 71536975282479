import {
    Box,
    Button,
    Stack, Text, Heading,useColorModeValue, Image, IconButton, useDisclosure, 
    Modal, ModalContent, ModalBody, ModalFooter, ModalCloseButton, ModalHeader, 
    ModalOverlay, FormControl, FormLabel, Input, 
    Editable, EditableInput, EditablePreview, Center
  } from "@chakra-ui/react"

import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { useState } from 'react';
import { User } from "firebase/auth";
import { doc, updateDoc, deleteField, FieldPath, setDoc } from "firebase/firestore";
import { ref, deleteObject, getDownloadURL, uploadBytes, UploadResult } from "firebase/storage";
import firebase from "../firebase";
import { useAuth } from "../AuthContext"

function PropertyCard({ name, value, color, imageurl }: {name:string; value:string; color:string; imageurl:string}) {

  const { isOpen: eIsOpen, onOpen:eOnOpen, onClose:eOnClose } = useDisclosure() 

  const [propertyname, setPropertyName] = useState(name)
  const [propertyvalue, setPropertyValue] = useState(value)
  const [propertycolor, setPropertyColor] = useState(color)
  const [propertyimageurl,] = useState(imageurl);
  const [imageUpload, setImageUpload] = useState<any | null>(null);
  const { currentUser } = useAuth()


  async function deletePropertyFromDatabase(user: User){
    const propertyRef = doc(firebase.db, 'properties', user.uid);

    await updateDoc(propertyRef, new FieldPath(name) , deleteField());

    const propertyStorageRef = ref(firebase.storage,`propertyimages/${user.uid}/${name}`);
    

    // Delete the file
    deleteObject(propertyStorageRef).then(() => {
      dOnClose();
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });

  }

  function s(url:string){
    window.open(url, "_blank");
  }

  function editPropertyInDatabase(user: User){

    const imageRef = ref(firebase.storage, `propertyimages/${user.uid}/${propertyname}`); 
    uploadBytes(imageRef, imageUpload).then((snapshot: UploadResult) => {
      getDownloadURL(snapshot.ref).then((url: string) => {
        setDoc(doc(firebase.db, "properties", user.uid), 
        {
          [propertyname]: {
            propertyvalue: propertyvalue,
            propertycolor: propertycolor,
            propertyimageurl: imageUpload ? url : propertyimageurl,
          },
        },{merge: true}).then(eOnClose);
      }
      );
    }
    );
  }

  const { isOpen: dIsOpen, onOpen:dOnOpen, onClose:dOnClose } = useDisclosure() 

  return (
    <>
        <Box
            w={'full'}  
            pos={'relative'}
            p={6}
            role={'group'}
            bg={"grey.100"}
            
            //zIndex={1}

            //maxW={'330px'}
            minWidth={{ base: '30vw', md: '22vw', lg: '18vw' }}
            maxW={{ base: '50vw', md: '22vw', lg: '18vw' }}
            minHeight="500px"
            maxHeight="600px"
              //bg={useColorModeValue('white', 'gray.800')}
              boxShadow={'2xl'}
              rounded={'lg'}
              >
              <Box
                rounded={'lg'}
                mt={2}
      
                pos={'relative'}
                 maxHeight={'230px'}>
                <Image
                  rounded={'lg'}
                  height={230}
                  width={282}
                  objectFit={'cover'}
                  src={imageurl}
                  onClick={() => s(`https://www.google.com/maps/search/?q=${name}`)}
                  cursor={'pointer'}
                />
              </Box>
              <Stack pt={10} align={'center'} pb={'5'}>
                <Heading fontSize={'xl'} fontFamily={'body'} fontWeight={500} textAlign={'center'}>
                  {name}
                </Heading>
                <Text color={'gray.500'} fontSize={'sm'} textTransform={'uppercase'}>
                    {color}
                </Text>
                <Stack direction={'row'} align={'center'}>
                  <Text color={'gray.600'}>
                    ${value}
                  </Text>
                </Stack>
                <Stack direction={'row'} w={'100%'} justifyContent='end' pos={'absolute'} bottom={'1'} right={'1'}>
                  <IconButton
                    onClick={() => eOnOpen()}
                    variant='outline'
                    w={'30px'} h={'30px'}
                    colorScheme='green'
                    aria-label='Call Sage'
                    size='xs'
                    icon={<EditIcon />}
                  />
                  <IconButton
                    variant='outline'
                    onClick={() => dOnOpen()}
                    w={'30px'} h={'30px'}
                    colorScheme='red'
                    aria-label='Call Sage'
                    size='xs'
                    icon={<DeleteIcon />}
                    />
                </Stack>
              </Stack>
        </Box>
          <Modal isOpen={dIsOpen} onClose={dOnClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Delete Property</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'white')}
                color={useColorModeValue('black', 'black')}
                boxShadow={'lg'}
                p={8}>
                <Text>Are you sure you want to delete {name} ?</Text>
                <Stack spacing={4}>
                  <Stack spacing={10} pt={2}>
                    <Button
                      onClick={() => deletePropertyFromDatabase(currentUser)}
                      loadingText="Submitting"
                      size="lg"
                      bg={'red.400'}
                      color={'white'}
                      _hover={{
                        bg: 'blue.500',
                      }}>
                      Delete
                    </Button>
                  </Stack>
                </Stack>
              </Box>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Modal isOpen={eIsOpen} onClose={eOnClose} >
            <ModalOverlay />
            <ModalContent maxW={{ base: '75vw', md: '50vw', lg: '50vw' }}>
              <ModalHeader>Edit Property</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
              <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'white')}
              color={useColorModeValue('black', 'black')}
              boxShadow={'lg'}
              p={8}>
              <Stack spacing={4}>
                <FormControl id="propertyname" >
                  <FormLabel>Name</FormLabel>
                    <Input onChange={(e) => { setPropertyName(e.target.value);}} defaultValue={name} disabled={true}/>
                </FormControl>
                <FormControl id="propertycolor" >
                  <FormLabel>Color</FormLabel>
                  <Editable>
                    <EditablePreview />
                    <Input defaultValue={color} onChange={(e) => { setPropertyColor(e.target.value); }}/>
                  </Editable>
                </FormControl>
                <FormControl id="propertyvalue" >
                  <FormLabel>Value</FormLabel>
                  <Editable>
                    <EditablePreview />
                    <Input defaultValue={value} onChange={(e) => { setPropertyValue(e.target.value); }}/>
                  </Editable>
                </FormControl>
                <FormLabel>Image</FormLabel>
                <Center>
                  <Input pt={2} ps={12} size="lg" type="file" onChange={(event) => {setImageUpload(event.target.files![0]);}}/>
                </Center>
                  <Button
                    onClick = {() => editPropertyInDatabase(currentUser)}
                    loadingText="Submitting"
                    size="lg"
                    bg={'green.400'}
                    color={'white'}
                    _hover={{
                      bg: 'blue.500',
                    }}>
                    Complete
                  </Button>
              </Stack>
            </Box>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
        </Modal>
            
            </>
  );
}
export default PropertyCard;        
