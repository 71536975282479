import { useContext, useState, useEffect, createContext, ReactNode } from "react"
import firebase from './firebase'
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, User} from "firebase/auth";


const AuthContext = createContext<any | null>(null)

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: {children: ReactNode}) {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  function signup(email: string, password: string) {
    return createUserWithEmailAndPassword(firebase.auth, email, password)
  }

  function login(email: string, password: string) {
    return signInWithEmailAndPassword(firebase.auth, email, password)
  }

  function logout() {
    return firebase.auth.signOut()
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebase.auth, (user) => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}