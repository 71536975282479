import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    ChakraProvider,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    InputRightElement,
    HStack,
    InputGroup,
    theme,
    FormErrorMessage,
    Image,
  } from '@chakra-ui/react';

import {Link as Route} from "react-router-dom";
import { useEffect, useState } from 'react';
import { ColorModeSwitcher } from "../ColorModeSwitcher"
import {User, UserCredential } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import firebase from '../firebase'
import { ref, uploadBytes, UploadResult, getDownloadURL } from 'firebase/storage';
import llogo from "../logo.png"
import { useAuth } from "../AuthContext"
import { FirebaseError } from 'firebase/app';

  export default function SignUp() {

    let navigate = useNavigate()

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [profileImageUpload, setProfileImageUpload] = useState<any | null>(null);
    
    const [badEmail, setBadEmail] = useState(false)
    const [badPass, setBadPass] = useState(false)

    const [emailErrorMessage, setEmailErrorMessage] = useState("")
    const [passErrorMessage, setPassErrorMessage] = useState("")

    const { currentUser } = useAuth()

    //const [error, setError] = useState("")
    //const [loading, setLoading] = useState(false)

    const { signup } = useAuth()

    useEffect(() => {

    },[currentUser]); 

    async function addUserToDatabase(user: User){

      //if (profileImageUpload == null) continue;    
      
      const imageRef = ref(firebase.storage, `profileimages/${user.uid}/${firstname}`); 

      uploadBytes(imageRef, profileImageUpload).then((snapshot: UploadResult) => {
        getDownloadURL(snapshot.ref).then((url: string) => {
          try{
            setDoc(doc(firebase.db, "users",user.uid), {
              firstname: firstname,
              lastname: lastname,
              email: email,
              //password: password,
              profileimageurl: url
            });
          }
          catch (e) {
            console.error("Error adding document: ", e);
          }
        }).catch((e)=>{
          console.error("Error ", e);
        });
      }).catch((e) =>{
        console.error("Error adding document: ", e);
      });
    }
    async function createAccount(){
      
      try {
        //setError("")
        //setLoading(true)
        const uc : UserCredential = await signup(email, password)
        const user = uc.user;
        addUserToDatabase(user)
        navigate("/app")
        sessionStorage.setItem('Auth Token', user!.refreshToken)
      } catch(error: unknown) {
        if (error instanceof FirebaseError) {
          if (error.code === "auth/email-already-in-use") {
            setBadEmail(true)
            setEmailErrorMessage("There is already an account associated with this email.");
          } else if (error.code === "auth/invalid-email") {
            setBadEmail(true)
            setEmailErrorMessage("The email address needs to be a valid.");
          } else if (error.code === "auth/weak-password") {
            setBadPass(true)
            setPassErrorMessage("The password needs to be atleast 6 characters.");
          }
          //setError("Failed to create an account")
        }
      }
      //setLoading(false)
  }

    return (
     <ChakraProvider theme={theme}>
        <ColorModeSwitcher justifySelf="flex-start" />
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Button height={'100px'} bgColor={"transparent"} _hover={{ bg: "transparent" }}  _active={{ color: 'transparent' }} onClick={() => navigate("/")} mb={2}>
            <Image
                  
                  borderRadius='full'
                  boxSize='100px'
                  src={llogo}
                  alignSelf={"start"}
              />
            </Button>
            <Heading fontSize={'4xl'} textAlign={'center'}>
              Sign up
            </Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              to enjoy all of our cool features
            </Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'white')}
            color={useColorModeValue('black', 'black')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <FormControl id="firstname" >
                    <FormLabel>First Name</FormLabel>
                    <Input value={firstname} onChange={(e) => { setFirstName(e.target.value); }} borderColor={'lightgray'} type="text" required />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastname"  >
                    <FormLabel>Last Name</FormLabel>
                    <Input value={lastname} onChange={(e) => { setLastName(e.target.value); }}borderColor={'lightgray'} type="text" required />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isInvalid={badEmail}>
                <FormLabel>Email address</FormLabel>
                <Input value={email} onChange={(e) => { setEmail(e.target.value); }} borderColor={'lightgray'} type="email" required />
                <FormErrorMessage>{emailErrorMessage}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={badPass}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input value={password} onChange={(e) => { setPassword(e.target.value); }} borderColor={'lightgray'} type="password" required />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}>
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{passErrorMessage}</FormErrorMessage>
              </FormControl>
              <Box justifyItems={"end"} textAlign={"end"}>
                <FormControl id="profilepicture">
                  <FormLabel>Select Profile Picture</FormLabel>
                  <Input size="lg" pt={2} ps={16} type="file" onChange={(event) => {setProfileImageUpload(event.target.files![0]);}}/>
                  <FormErrorMessage>No Photo Added</FormErrorMessage>
                </FormControl>
              </Box>
              <Stack spacing={10} pt={2}>
                <Button
                  onClick={() => createAccount()}
                  loadingText="Submitting"
                  size="lg"
                  bg={'blue.400'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.500',
                  }}>
                  Sign up
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={'center'}>
                  Already a user? <Link as={Route} to="/login" color={"blue.400"}>Login</Link>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
      </ChakraProvider>
    );
  }