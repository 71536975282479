import {
    Container,
    Heading,
  } from "@chakra-ui/react"

function Applications() {

  return (
    <Container maxW={'7xl'} maxHeight="500px" justifyContent={'center'} display={'flex'}>
        <Heading>
          Coming Soon!
        </Heading>
  </Container>
  );
}
export default Applications;