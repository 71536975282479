import {
    Box,
    Button,
    Stack, useColorModeValue, ModalHeader, ModalOverlay, ModalContent, Modal, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, InputGroup, ModalFooter, FormErrorMessage, Center,
  } from "@chakra-ui/react"

  import { useDisclosure } from "@chakra-ui/react";
  import { useState } from 'react';
  import firebase from "../firebase";
  import {doc, getDoc, setDoc } from "firebase/firestore";
  import { User } from "firebase/auth";
  import {ref,
    uploadBytes,
    getDownloadURL,
    UploadResult,
  } from "firebase/storage";
  import { useAuth } from "../AuthContext"

function PropertyBar() {

    const { isOpen, onOpen, onClose} = useDisclosure() 
    const [propertyname, setPropertyName] = useState("")
    const [propertyvalue, setPropertyValue] = useState("")
    const [propertycolor, setPropertyColor] = useState("")
    const [imageUpload, setImageUpload] = useState<any | null>(null);
    const [badName, setBadName] = useState(false)
    const [nameErrorMessage, setNameErrorMessage] = useState("")
    const { currentUser } = useAuth()

    async function addPropertyToDatabase(user: User){
      const imageRef = ref(firebase.storage, `propertyimages/${user.uid}/${propertyname}`); 

      const docRef = doc(firebase.db, "properties",user.uid);
      const docSnap = await getDoc(docRef);
      const profile = docSnap.data()
      
      if(profile != null){
        if(propertyname in profile!){
          setBadName(true)
          setNameErrorMessage("Choose a property name that doesn't already exist!")
          return
        }
      }
      if(propertyname === ""){
        setBadName(true)
        setNameErrorMessage("Field is required")
        return
      }

      uploadBytes(imageRef, imageUpload).then((snapshot: UploadResult) => {
        getDownloadURL(snapshot.ref).then((url: string) => {
          setDoc(doc(firebase.db, "properties", user.uid), 
          {
            [propertyname]: {
              propertyvalue: propertyvalue,
              propertycolor: propertycolor,
              propertyimageurl: imageUpload ? url : "",
            },
          },{merge: true}).then(onClose);
        }
        );
      }
      );
    }

  return (
      <>
         <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'white')}
          color={useColorModeValue('black', 'black')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="propertyname" isInvalid={badName}>
              <FormLabel>Name</FormLabel>
              <Input onChange={(e) => { setPropertyName(e.target.value); }} borderColor={'lightgray'} type="text" />
              <FormErrorMessage>{nameErrorMessage}</FormErrorMessage>
            </FormControl>
            <FormControl id="propertycolor" >
              <FormLabel>Color</FormLabel>
              <Input onChange={(e) => { setPropertyColor(e.target.value); }} borderColor={'lightgray'} type="text" />
            </FormControl>
            <FormControl id="propertyprice" >
              <FormLabel>Value</FormLabel>
              <InputGroup>
                <Input onChange={(e) => { setPropertyValue(e.target.value); }} borderColor={'lightgray'} type="text" />
              </InputGroup>
            </FormControl>
              <FormLabel>Image</FormLabel>
              <Center>
                <Input size="lg" pt={2} ps={12} type="file" onChange={(event) => {setImageUpload(event.target.files![0]);}}/>
              </Center>
              <Button
                onClick={() => addPropertyToDatabase(currentUser!)}
                loadingText="Submitting"
                size="lg"
                bg={'green.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}>
                Create
              </Button>
          </Stack>
        </Box>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
        <Box>
            <Stack direction='row' spacing={4} align='center' justifyContent={'center'}>
                <Button onClick={onOpen} colorScheme='teal' variant='solid' w={'350px'}>
                    Add Property
                </Button>
            </Stack>
        </Box>
      </>
  );
}
export default PropertyBar;        
