import { Box, Container, Heading, GridItem, Grid, useColorModeValue, VStack} from "@chakra-ui/react"
import Chart from "react-apexcharts"

const x = {
  options: {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun']
    },
    dataLabels: {
      enabled: false,
      orientation: 'horizontal',
      position: 'center', // bottom/center/top
      style: {
        fontSize: '8pt',
        colors: ['#000']
    },
    }
  },
  series: [{
    name: 'Income',
    type: 'column',
    data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8]
  }, {
    name: 'Cashflow',
    type: 'column',
    data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5]
  }, {
    name: 'Revenue',
    type: 'column',
    data: [20, 29, 37, 36, 44, 45, 50]
  }]
};

const y = {
  options: {
    chart: {
      id: 'donut',
    },
    dataLabels: {
      enabled: false,
      orientation: 'horizontal',
      position: 'center', // bottom/center/top
      style: {
        fontSize: '8pt',
        colors: ['#000']
    },
    }
  },
  series: [44, 55],
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          name: {
          },
          value: {
            fontSize: '22px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
            formatter: function (val: any) {
              return val
            }
          }
        }
      }
    }
  }
};

function Dashboard() {

  return (
    <Container maxW={'10xl'} maxHeight="100%" overflowY="auto">
        <Grid templateColumns='repeat(10, 1fr)' templateRows='repeat(2, 1fr)'
        >
          <GridItem colSpan={{base: 10 ,sm: 10, md: 10, lg: 7}} rowSpan={{sm: 2, md: 2, lg: 2}} h='100%'  w='100%' >
            <Box h={'100%'} >
              <Heading pt={5} pl={5} size={'md'}>Overview</Heading>
              <Box display={"flex"} rounded='md' justifyContent={"center"}>
                <Box border= "1px" rounded={'md'} p={6} mt={2} ml={2} bg={useColorModeValue('white', 'white')} w={'789px'} h={'526px'}>
                  <Chart
                    options={x.options}
                    series={x.series}
                    type="bar"
                    width="100%"
                  />
                </Box>
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={{base: 10, sm: 10, md: 5, lg: 3}} rowSpan={{sm: 1, md: 1, lg: 1}} h='100%' w='100%'>
            <Box h={'100%'}>
              <Heading pt={5} pl={5} size={'md'}>Activity</Heading>
              <VStack display={"flex"} rounded='md' alignItems="center" justifyContent={"center"} color={'black'}>
                  <Box mt={2} border= "1px" rounded={'md'} p={6} bg={useColorModeValue('white', 'white')} w={'80%'} h={'50px'}>Raheem paid his rent</Box>
                  <Box border= "1px" rounded={'md'} p={6} bg={useColorModeValue('white', 'white')} w={'80%'} h={'50px'}>Malik paid his rent</Box>
                  <Box border= "1px" rounded={'md'} p={6} bg={useColorModeValue('white', 'white')} w={'80%'} h={'50px'}>Jabari paid his rent</Box>
                  <Box border= "1px" rounded={'md'} p={6} bg={useColorModeValue('white', 'white')} w={'80%'} h={'50px'}>Khalilah paid her rent</Box>
              </VStack>
            </Box>
          </GridItem>
          <GridItem colSpan={{base:10, sm: 10, md: 5, lg: 3}} h='100%' w='100%'>
            <Box h={'100%'}>
              <Heading pt={5} pl={5} size={'md'}>Rent Status</Heading>
              <Box display={"flex"} rounded='md' justifyContent={"center"}>
                <Box border= "1px" rounded={'md'} p={6} mt={2} ml={2} bg={useColorModeValue('white', 'white')} w={'333px'} h={'222px'}>
                  <Chart
                      options={y.options}
                      series={y.series}
                      type="donut"
                      width="100%"
                    />
                </Box>
              </Box>
            </Box>
          </GridItem>
        </Grid>
  </Container>
  );
}
export default Dashboard;