import {
BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AppMenu from "./pages/AppMenu";
import Home from "./pages/Home";
import LandlordLogin from "./pages/LandlordLogin";
import PaymentPortal from "./pages/PaymentPortal";
import SignUp from "./pages/SignUp";
import { AuthProvider, useAuth } from "./AuthContext"

function Root() {
  
  //const [authorized, setAuthorized] = useState<boolean | null>(false)
  
  //useEffect(() => {
  //},[authorized]);

  const { currentUser } = useAuth()
  
  //let authToken = sessionStorage.getItem('Auth Token')

  return (
    <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/payment" element={<PaymentPortal />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LandlordLogin />} />
            <Route path="/app" element={ currentUser ? <AppMenu/> : <Navigate to="/"/>}/>
            <Route path="*" element={<Navigate to="/"/>} />
    </Routes>
  );
}

export default function Main() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Root />
      </AuthProvider>
    </BrowserRouter>
  );
}