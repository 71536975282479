import {
  ChakraProvider,
  Box,
  theme,
  Container,
  Heading,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "../ColorModeSwitcher"

export const PaymentPortal = () => (
  <ChakraProvider theme={theme}>

    <ColorModeSwitcher justifySelf="flex-start" />
    <Container maxW={'7xl'}>
        <Box>
            <Heading>Coming Soon!</Heading>
        </Box>
    </Container>
  </ChakraProvider>
)

export default PaymentPortal;